import React from "react"
// import { useIsDarkMode } from 'state/user/hooks'
import styled from "styled-components"

export const BodyWrapper = styled.div`
  position: relative;
  max-width: 420px;

  width: 100%;
  background: ${({ theme }) => theme.bg1};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04), 0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 12px;
  padding: 1rem;
`

// const astro_img = require('assets/images/bg1.png')
// const Astro = styled.img`
//   position: fixed;
//   z-index: 1;
//   right: 0;
//   bottom: 0;
//   width: 960px;
// `

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  // const darkmode = useIsDarkMode()
  return (
    <BodyWrapper>
      {/* <div style={{
        position: 'relative',
        zIndex: 2
      }}> */}
      {children}
      {/* </div>
      <div style={{
        display: darkmode ? 'none': 'block'
      }}> */}
      {/* <Astro id={'astro'} src={astro_img}/> */}
      {/* </div> */}
    </BodyWrapper>
  )
}
