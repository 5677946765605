export default {

  title: 'Hotpot Swap',
  icon: require('assets/images/hotpot_logo.png'),
  platformToken: 'POTS',

  // hideHeader
  
  hideChart: true,
  useStake: false,
  
  // closeDefaultlist: false,
  closeDefaultlist: true,


  // uniswap mainnet config
  // factoryAddress: "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f",
  // routerAddress: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
  // wethAddress: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
  // multicallAddress: "0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441",
  // initCodeHash: "0x96e8ac4277198ff8b6f785478aa9a39f403cb768dd02cbee326c3e7da348845f"

  // governanceAddress: "0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F",
  // timelockAddress: "0x1a9C8182C09F50C8318d769245beA52c32BE35BC",
  // uniAddress: "0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984",

  // routerv1Address: "0xc0a47dFe034B400B47bDaD5FecDa2621de6c4d95",
  // migratorAddress: "0x16D4F26C15f3658ec65B1126ff27DD3dF2a2996b",
  // argentWalletDetectorAddress: "0xeca4B0bDBf7c55E9b7925919d03CbF8Dc82537E8",
  // unisocksAddress: "0x65770b5283117639760beA3F867b69b3697a91dd",
  // ENSRegistryWithFallbackAddress: "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e",
  // DGDAddress: "0xE0B7927c4aF23765Cb51314A0E0521A9645F0E2A",
  // merkleDistributorAddress: "0x090D4613473dEE047c3f2706764f49E0821D256e",

  // rinkeby config
  // factoryAddress: "0x96938B39c435a25C4884bbaB7dC32bdECCd1E831",
  // routerAddress: "0xc13AA3C5b6fAd1E96bCaD540bf091f274d2AfFFd",

  // already updated
  factoryAddress: "0x6A0724ED8B8Eb0321bb8F81DaE9C59107B78F49e", // extra txfeeto
  routerAddress: "0x703DaF890eC6C3Dc14De6EEB7d25F842d358851f", // extra txfeeto
  multicallAddress: "0x757938BBD9a3108Ab1f29628C15d9c8715d2F481",
  initCodeHash: "0xec631b007f6201ca54cbcceef253eb7114f01b25efb62c7b975a196b934b1143", // extra txfeeto

  // need to be deployed
  wethAddress: "0xbD52FCD80dc96b6C908a19970FF727E88EdA5Ba7",

  // not sure which ones are needed
  ENSRegistryWithFallbackAddress: "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e",
  governanceAddress: "0x1da58605FF54950B99e89F36Ec1C136d79D82654",
  timelockAddress: "0xb909608fEc374f9A34550863867E53DF3cAC6f23",
  uniAddress: "0xcDe8b6Abb09daCDB73df1095da8392DC5CE07cA1",

  // old
  // governanceAddress: "0xb76D19d006dC84ff3d576b08AbD1A04D25eDF2Cb",
  // timelockAddress: "0x84d9e8310bfcD8df094850e556C8B26cDacb981e",
  // uniAddress: "0x40802692a4E3240994488322D69df904ab02FB75",

  // no v1
  routerv1Address: "0x0000000000000000000000000000000000000001",
  migratorAddress: "0x0000000000000000000000000000000000000002",
  // no need on rinkby
  argentWalletDetectorAddress: "0x0000000000000000000000000000000000000003",
  unisocksAddress: "0x0000000000000000000000000000000000000004",
  DGDAddress: "0x0000000000000000000000000000000000000005",
  merkleDistributorAddress: "0x0000000000000000000000000000000000000006",
  

  // private config
  // factoryAddress: "0x6fbDaeee8945314b1E2948cf8922E652f35b60F3",
  // routerAddress: "0x6DBE6975Eb3cD1917Cf44f82Ec7e2546707B21f5",
  // wethAddress: "0x960E7cA0f80Ef94D35F2cae60aaeA7dbc9c7D204",
  // multicallAddress: "0xE405E29a3CC08a307fb702EB9D48bE4F517c9ed7",
  // initCodeHash: "0x926ea93c96b51a1c804c03aaf0eefb0ea5e6fdc95afb459db2ec84843b0c7e7f",

  // governanceAddress: "0xe4858809F6aAd3b675F82229E8F62a85bd96faEf",
  // timelockAddress: "0x4E6f10c2a1edF49Cab4a44fB2F2F78a69bECC1D7",
  // uniAddress: "0x300a6c863f3094645DC3F74cf5ff5C864556De8b",

  // routerv1Address: "0x0000000000000000000000000000000000000001",
  // migratorAddress: "0x0000000000000000000000000000000000000002",
  // argentWalletDetectorAddress: "0x0000000000000000000000000000000000000003",
  // unisocksAddress: "0x0000000000000000000000000000000000000004",
  // ENSRegistryWithFallbackAddress: "0xe047d9918E285A16A867ffa8f117550082b78985",
  // DGDAddress: "0x0000000000000000000000000000000000000005",
  // merkleDistributorAddress: "0x0000000000000000000000000000000000000006",
}

// find address in code
// ['"]0x[a-fA-F0-9]{40}['"]

// TestCoinZ(TCZ)
// 0x949663873487C22f6496C5b54B54BF22693000a5